import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  NavigationExtras
} from '@angular/router';

import { CommonService } from '../common/common.service'
import { UserService } from '../user/user.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private http: HttpClient,
    private commonService: CommonService,
    private userService: UserService,
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const token = this.commonService.getJsonValue('token');

    if (!token) {
      this.router.navigate(['/auth/login']);
      return false;
    }

    return true;
  }

  async canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    return await this.canActivate(route, state);
  }


}
