import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';

import { CommonService } from '../../../../../service/common/common.service';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {

  public username = '';
  public companyname = '';
  public firstname = '';
  public lastname = '';
  public usertype = '';
  public timer;

  constructor(
    private router: Router,
    public commonService: CommonService
  ) { }

  ngOnInit() {
    this.timer = setInterval(() => {
      this.bindUserInfo();
    }, 500);
  }

  bindUserInfo() {
    if (this.usertype) {
      clearInterval(this.timer);
    }
    console.log('-----no login-----');
    //get user detail
    this.username = this.commonService.getJsonValue('username');
    this.usertype = this.commonService.getJsonValue('usertype');
    this.companyname = this.commonService.getJsonValue('companyName');
    this.firstname = this.commonService.getJsonValue('firstName');
    this.lastname = this.commonService.getJsonValue('lastName');
    let accessLevel = this.commonService.getJsonValue('accessLevel');
  }

  logout() {

    // this.checkTokenService.close();
    this.commonService.clearToken();
    this.router.navigate(['auth/login']);
  }
}
