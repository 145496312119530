import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../common/common.service'

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private commonService: CommonService
  ) {
  }

  async getUser():Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>('/User/item').subscribe(data => {
        let result: any = {};
        result = data;
        if (typeof (data) === 'string') {
          console.log(data);
          resolve(data);
          return false;
        }
        // set user detail
        if (typeof (data.user) === 'object') {
          this.commonService.setJsonValue('usertype', data.user.type);
          this.commonService.setJsonValue('userstatus', data.user.status);
          this.commonService.setJsonValue('firstName', data.user.firstName);
          this.commonService.setJsonValue('lastName', data.user.lastName);
          this.commonService.setJsonValue('generalEmail', data.user.generalEmail);
          this.commonService.setJsonValue('phoneNo', data.user.phoneNo);
          this.commonService.setJsonValue('accessLevel', data.user.accessLevel);
          this.commonService.setJsonValue('companyName', data.user.companyName);
          this.commonService.setJsonValue('success', 'true');
        }
        resolve(result);
      }, error => {
        resolve(error.message);
      });
    });
  }
}
