import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Buffer } from 'buffer';
import { DialogService } from '../dialog/dialog.service';
import { CryptService } from '../crypt/crypt.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private http: HttpClient,
    private dialogService: DialogService,
    private cryptService: CryptService
  ) { }

  parseParam(json) {
    return Object.keys(json).map(v => {
      if (json[v] != null) {
        return v + '=' + encodeURIComponent(json[v]);
      }
    }).filter(a => {
      return a;
    }).join('&');
  }

  dateFormate(time) {
    let times = time.split('/');
    if (times.length > 0) {
      return times[1] + '/' + times[0] + '/' + times[2];
    }
  }

  ConvertNgbDateStructToDate(date: NgbDateStruct): Date {
    let result: Date = null;
    if (date) {
      return new Date(date.year, date.month - 1, date.day);
    } else {
      return null;
    }
  }

  ConvertDateToString(date: Date): string {
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var strmonth = month >= 1 && month <= 9 ? '0' + month : month;
    var strday = day >= 1 && day <= 9 ? '0' + day : day;
    return strday + '/' + strmonth + '/' + date.getFullYear();
  }

  getToday() {
    var now = new Date();
    var year = now.getFullYear();
    var month = now.getMonth();
    var day = now.getDate();
    return new Date(year, month, day);
  }

  /**
   *
   * @param obj return a object copy
   */
  deepcopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }
  /**
   * Function to sort alphabetically an array of objects by some specific key.
   *
   * @param {String} property Key of the object to sort.
   */
  dynamicSort(property) {
    var sortOrder = 1;

    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  }
  distinctBy(arr, key) {
    var hash = {};
    arr = arr.reduce(function (item, next) {
      hash[next[key]] ? '' : (hash[next[key]] = true && item.push(next));
      return item;
    }, []);
    console.log(arr);
    return arr;
  }
  setJsonValue(key: string, value: any) {
    if (!value && value != 0 && value != false) {
      value = '';
    }
    else {
      value = value.toString();
    }
    this.cryptService.secureStorage.setItem(key, value);
  }
  // Get the json value from local storage
  getJsonValue(key: string) {
    return this.cryptService.secureStorage.getItem(key);
  }
  removeItem(key: string) {
    return this.cryptService.secureStorage.removeItem(key);
  }
  // Clear the local storage
  clearToken() {
    let rememberMe = this.getJsonValue('rememberMe');
    let result = this.cryptService.secureStorage.clear();
    if (rememberMe) {
      this.setJsonValue('rememberMe', rememberMe);
    }
    return result;
  }

  paramsEncode(json) {
    let str = json;
    if (typeof (json) == 'object') {
      str = this.parseParam(json);
      str = encodeURIComponent(str);
      return new Buffer(str).toString('base64');
    } else {
      str = encodeURIComponent(str);
      return '_params=' + new Buffer(str).toString('base64');
    }
  }

  paramsDecode(paramsEncode) {
    let paramsStr = paramsEncode['_params'];
    if (!paramsStr) {
      return paramsEncode;
    }
    let params = {};

    paramsStr = decodeURIComponent(new Buffer(paramsStr, 'base64').toString());

    let paramsArray = paramsStr.split('&');

    for (var i = 0; i < paramsArray.length; i++) {
      let item = paramsArray[i].split('=');
      params[item[0]] = decodeURIComponent(item[1]);
    }
    return params;
  }
  //获取服务器图片资源
  async getImg(path) {
    return new Promise((resolve, reject) => {
      this.http.post('/Resource/read-portraits?path=' + path, '').subscribe(data => {
        let flag = this.dialogService.getErrorCode(data);
        if (flag) {
          let result: any = {};
          result = data;
          resolve(result);
        }
      }, error => {
        resolve(error.message);
      });
    });
  }

  //获取服务器签名文件资源
  async getSignature(path) {
    return new Promise((resolve, reject) => {
      this.http.post('/Resource/read-sigrate?path=' + path, '').subscribe(data => {
        let flag = this.dialogService.getErrorCode(data);
        if (flag) {
          let result: any = {};
          result = data;
          resolve(result);
        }
      }, error => {
        resolve(error.message);
      });
    });
  }
  async getcover(path) {
    return new Promise((resolve, reject) => {
      this.http.post('/Resource/read-cover?path=' + path, '').subscribe(data => {
        let flag = this.dialogService.getErrorCode(data);
        if (flag) {
          let result: any = {};
          result = data;
          resolve(result);
        }
      }, error => {
        resolve(error.message);
      });
    });
  }
  async getUwLogo(path) {
    return new Promise((resolve, reject) => {
      this.http.post('/Resource/read-uw-logo?path=' + path, '').subscribe(data => {
        let flag = this.dialogService.getErrorCode(data);
        if (flag) {
          let result: any = {};
          result = data;
          resolve(result);
        }
      }, error => {
        resolve(error.message);
      });
    });
  }
  openNewWindown(target: string, name?: string) {
      if (!target) {
        return false;
      }
      name = name ? name : 'new_window';
      const option = 'resizable=yes,scrollbars=yes';
      const nw = window.open(target, name, option);
      nw.moveTo(0, 0);
      nw.resizeTo(nw.screen.availWidth, nw.screen.availHeight);
  }
}
