import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js'

@Injectable({
  providedIn: 'root'
})
export class CryptService {

  key = CryptoJS.enc.Latin1.parse('6v9y$B&E)H@McQfT');

  constructor() { }

  encrypt(params: string | number) {
    return CryptoJS.AES.encrypt(params, this.key, { iv: this.key, mode: CryptoJS.mode.CBC }).toString();
  }

  decrypt(params: string) {
    return CryptoJS.AES.decrypt(params, this.key, { iv: this.key, mode: CryptoJS.mode.CBC }).toString(CryptoJS.enc.Utf8);
  }
}
