import { Inject, Injectable, InjectionToken } from '@angular/core';
import { StorageService } from 'ngx-webstorage-service';
import { CryptService } from './crypt.service';

export const APP_SERVICE_STORAGE =
  new InjectionToken<StorageService>('sZMk*BNM6HIPS!*JYTnbeh%pGhIDh8Eo');

@Injectable({
  providedIn: 'root'
})
export class AppStorageService {

  constructor(@Inject(APP_SERVICE_STORAGE) private storage: StorageService, private cryptService: CryptService) { }

  public setItem(key: string, value: string | number) {
    let encrypted = this.cryptService.encrypt(value);
    this.storage.set(key, encrypted);
  }

  public getItem(params: string) {
    if (this.storage.has(params)) {
      return this.cryptService.decrypt(this.storage.get(params));
    }
    return '';
  }

  public setJsonItem(key: string, value: object) {
    this.storage.set(key, this.cryptService.encrypt(JSON.stringify(value)));
  }

  public getJsonItem(params: string) {
    if (this.storage.has(params)) {
      return JSON.parse(this.cryptService.decrypt(this.storage.get(params)));
    }
    return '';
  }

  public removeItem(params: string) {
    if (this.storage.has(params)) {
      this.storage.remove(params);
    }
  }

  public clear() {
    this.storage.clear();
  }
}
