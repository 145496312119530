export class NextConfig {
  public static config = {
    layout: 'vertical', // vertical, horizontal
    subLayout: '', // horizontal-2
    collapseMenu: false,
    layoutType: 'menu-light', // menu-dark, menu-light, dark
    headerBackColor: 'background-purple', // background-blue, background-red, background-purple, background-info, background-dark
    rtlLayout: false,
    navFixedLayout: true,
    headerFixedLayout: false,
    boxLayout: false,
  };
}

export class CKEditorConfig {
  public static default = {
    removePlugins: 'about,forms,language,save,newpage,preview,print,templates',
    toolbarGroups: [
      { name: 'mode' },
      { name: 'undo' },
      { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
      { name: 'tools' },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'insert' },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'] },
      { name: 'links' },
      { name: 'styles' },
      { name: 'colors' }
    ],
    scayt_autoStartup: false,
    allowedContent: true,
    entities: false,
    disableNativeSpellChecker: false,
    height: 450
  };
}