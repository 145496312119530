
const backendAUHost = 'https://uwapi-au.taxauditinsurance.com';
const backendNZHost = 'https://staging.asop.com.nz';
const backendCAHost = 'https://staging.asop.com.ca';
const payaiUrl = 'https://appsvc2-stg-payaiau.azurewebsites.net';

export const environment = {
  production: true,
  backendAUHost: backendAUHost,
  backendNZHost: backendNZHost,
  backendCAHost: backendCAHost,
  apiAUBasePath: backendAUHost + '/api/uw',
  apiNZBasePath: backendNZHost + '/api',
  apiCABasePath: backendCAHost + '/api',
  payaiUrl: payaiUrl,
  officialWebsite: 'https://www.accountancyinsurance.com.au',
  companyName: 'Accountancy Insurance',
  companyEmail: 'info@accountancyinsurance.com.au',
  companyPhone: '1300 650 758',
};
