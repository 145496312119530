import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { CommonService } from './service/common/common.service';
import { CryptService } from './service/crypt/crypt.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private http: HttpClient,
        private cryptService: CryptService,
        private commonService: CommonService)
    { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // to get blog data from public site
        if (req.url.startsWith('https://') || req.url.startsWith('http://')) {
            if (!req.url.endsWith('_allow_anonymous=true')) {
                let wpToken = this.commonService.getJsonValue('wpToken');
                return next.handle(req.clone({
                    headers: req.headers.set('Authorization', 'Bearer ' + wpToken)
                }));
            }
            return next.handle(req);
        }
        //加密?后面的参数
        let apiurl = environment.apiAUBasePath;
        if (req.url.indexOf('/Resource') === 0) {
          apiurl = apiurl.replace('/api/uw', '/api');
        }
        let urls = req.url.split('?');
        let newurl = apiurl + urls[0];
        if(urls.length > 1){
            newurl += '?_param='+encodeURIComponent(this.cryptService.Crypt(urls[1]));
        }
        let token = this.commonService.getJsonValue('token');
        let contentType = 'application/json';
        if(req.body instanceof FormData){
            contentType = '';
        }
        let authReq;

        if (req.url !== '/user/login' && token) {
            if(contentType){
                authReq = req.clone({
                    url: newurl,
                    headers: req.headers.set('Authorization', 'Bearer ' + token)
                            .set('Content-Type', contentType),
                    body: this.parseData(req.body,contentType)
                });
            }else{
                authReq = req.clone({
                    url: newurl,
                    headers: req.headers.set('Authorization', 'Bearer ' + token).delete('Content-Type'),
                    body: this.parseData(req.body,contentType)
                });
            }
        }else{
             authReq = req.clone({
                url: newurl,
                headers: req.headers.set('Content-Type', contentType),
                body: this.parseData(req.body,contentType)
            });
        }
        return next.handle(authReq).do((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                if (event instanceof HttpResponse) {
                    if (event.headers.get('Token-Expired') == 'true') {
                        this.router.navigate(['/session-error'],
                            {
                                queryParams: { _params: this.commonService.paramsEncode({ type: 'expired2' }) }
                            });
                    }
                }
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                console.log(err);
                if (err.status === 401) {
                    this.commonService.clearToken();
                    this.router.navigate(['auth/session-error'],
                        {
                            queryParams: { _params: this.commonService.paramsEncode({ type: 'access_denied' }) }
                        });
                }
                else {

                }
            }
        });
    }
    //加密
    public parseData(obj,contentType){
        if(obj){
            if(contentType == 'application/json'){
                const newdata={};
                newdata['_param']=this.cryptService.Crypt(JSON.stringify(obj) );
                return newdata;
            }else{//form-data提交方式
                let formData = obj;
                let newformData = new FormData();
                formData.forEach((value,key)=>{
                    if(value instanceof File){
                        newformData.append(key,value);
                    }else{
                        let newkey=this.cryptService.Crypt(key);
                        let newvalue=this.cryptService.Crypt(value);
                        newformData.append(newkey,newvalue);

                    }
                });
                return newformData;
            }
        }
    }
}
