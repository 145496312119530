import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalcDateDurationPipe } from './calc-date-duration/calc-date-duration.pipe';
import { ConvertDateFormatPipe } from './convert-date-format/convert-date-format.pipe';
import { HtmlPipe } from './html/html.pipe';



@NgModule({
  declarations: [CalcDateDurationPipe, ConvertDateFormatPipe, HtmlPipe],
  exports: [
    CalcDateDurationPipe,
    ConvertDateFormatPipe,
    HtmlPipe
  ],
  imports: [
    CommonModule
  ]
})
export class PipeModule { }
